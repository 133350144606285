/* website: 2778-paulalbertchevrolet2
 * created at 2020-04-01 10:15 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/gmc-preferred.scss";
@import "../utils/icons.scss";

.widget-sr{
    &.dealer__paulalbertchevroletbuickgmc{
        .price.is-price,
        .vehicle-payment-amount.is-price,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .taxes-detail .highlight.is-price{
            color: rgb(248, 39, 63);
        }
        .listing-used-button-loading.sr-button-1{
            background: #124a9d;
        }
    }
}
